import React, {Component} from 'react';
import {BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import FetchPosts from './posts';
import PostDetail from './postsDetails';
import './App.css';

class App extends Component {
  render() {
  return (
    <BrowserRouter>
      <Switch>
          <Route path="/post/:id"  component = {PostDetail} />
          <Route path="/" exact component = {FetchPosts} />
        
      </Switch>
    </BrowserRouter>
  );
}
}

export default App;
