import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';

class PostDetail extends Component {
    state = {
        posts :{ body : '', title: ''}
    }

    componentDidMount () {
        fetch(`https://jsonplaceholder.typicode.com/posts/${this.props.match.params.id}`)
        .then(response => response.json())
        .then(posts => this.setState({posts}))
    }

    render() {
        return (
            <div>
                <h2>{this.state.posts.title}</h2>
                <article>{this.state.posts.body}</article>
                <p><Link to="/">back to post</Link></p>
       
            <Helmet>
                <title>{this.state.posts.title}</title>
                <meta name="description" content={this.state.posts.body} />
            </Helmet>
             </div>
        )

    }
}

export default PostDetail;